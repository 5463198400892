import React, { useContext, useEffect, useState,useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Button } from "react-bootstrap";
import { message } from "antd";
import qr from "../../images/ludo/qr.png"
import lable from "../../images/lable.png"
import { compress } from 'image-conversion';

import axiosInstance from "../../axiosInstance";
const Recharge = (props) => {
  const textRef = useRef(null);
const [amount , setAmount] = useState(0)
const [upi , setUPI] = useState()
const [qrcode , setQR] = useState()
const [upiId , setUPIId] = useState()
const [utr , setUtr] = useState()
const [image3, setImage3] = useState(null);

const [utrShow , setUtrShow] = useState(true)
const [offer , setOffer] = useState(0)
const [offerData , setOfferData] = useState([{offer:4,price:1000},{offer:6,price:2000},{offer:8,price:5000},{offer:10,price:10000},{offer:12,price:20000},{offer:14,price:30000},])
  const user = useContext(UserContext);
  let navigate = useNavigate();
  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId == null) {
        window.location.reload(true);
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
  getUpi()
  }

  const getUpi = () => {
    axiosInstance.post("/upi/active").then((response) => {
      setUPIId(response.data.data[0]._id);
      setUPI(response.data.data[0].name);
      setQR(response.data.data[0].qr);
    });
  };
  const convertBlobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  };
  const handleInputChange1 = async(event) => {
    const image11 = event.target.files[0];
    const imagename = event.target.name;
if(image11.size/1024 >30 ){
  const compressedImage = await compress(image11, {
    quality: 0.1,
});
    const file11 = convertBlobToFile(compressedImage, 'imagename');
const newImage = {[imagename]:file11}
 const reader = new FileReader();
    reader.onload = () => setImage3(reader.result);
    reader.readAsDataURL(newImage[imagename]);
}
else{
  const image = { [event.target.name]: event.target.files[0] };
  const reader = new FileReader();
  reader.onload = () => setImage3(reader.result);
  reader.readAsDataURL(image[event.target.name]);
}
  };
  const handleCopyClick = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand('copy');
      alert('Text copied to clipboard!');
    }
  };

  const onPay = () => {
    if(amount !== undefined && amount !==''){
      if(amount>99){
        setUtrShow(false)
      }
    
    }
   else{
    message.error("Plese Enter Amount");

   }
   };
   const onRecharge = (e) => {
    const data = {
 
  transactionDate: new Date(),
  member: user.userId,
  upiId: upi,
  amount:amount,
 image:image3,
  status:"requested",
  bonus:(amount/100)*offer, 
  utr:utr
    };
  
     if(data.image!==undefined &&data.image!==null  && data.member!==undefined&& data.amount!==undefined&& data.status!==undefined && data.image!==''&& data.status!==''&& data.amount!==''&& data.member!==''){
      axiosInstance.post("/payment", data).then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Record Already Exists");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Record saved successfully");
         navigate('/wallet')
        } else message.error("Something wrong. Please try again...!");
      });
   }
   else{
    message.error("Please fill out all required fields. And submit again...!");
   }
   
  };
  const onOfferPay = (e) => {
  
   setOffer(e.offer)
   setAmount(e.price)
   setUtrShow(false)
  };
  return (
<div className="realludokingsize">
  <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
    <div class="offcanvas-header">
  <div class="offcanvas-title h5"id="offcanvasBottomLabel">How To Play Games &amp; Earn?</div>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body small">
   Your Video
    </div>
  </div>
      <div className="p-3">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <a href="#/Wallet">
              <button className="btn btn-primary border" onClick={()=>setUtrShow(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-2">
                  <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
                </svg>
                <span className="text-capitalize">Back</span>
              </button>
            </a>
          </div>
          <button type="button" className="d-flex align-items-center btn btn-outline-primary btn-md" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-1">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
            </svg>
            <span className="text-capitalize">guide</span>
          </button>
        </div>
        <div className="mb-3  shadow card">
 

                <div className="form-group">
              <div className="d-flex flex-column justify-content-center align-items-center">
             


             
                <div id="whichPG" className="p-0 mt-4 pe-3 ps-3 w-100">
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="cashfree" className="form-check-input" value="cashfree" />
                    <label htmlFor="cashfree" className="form-check-label">
                      <h5 className="text-capitalize">UPI</h5>
                    </label>
                  </div> */}
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="phonepe" className="form-check-input" value="phonepe" checked />
                    <label htmlFor="phonepe" className="form-check-label">
                      <h5 className="text-capitalize">UPI-2</h5>
                    </label>
                  </div> */}
               {utrShow && <label htmlFor="amount" className="form-label w-100 text-start">Enter Amount</label>}
               {!utrShow && <label htmlFor="amount" className="form-label w-100 text-center text-decoration-underline m-0">Payable Amount: ₹ {amount}</label>}

                     {utrShow && <div className="input-group mb-4">
                  <span className="input-group-text bg-light text-dark">₹</span>
                  <input type="number" placeholder="Amount" id="amount" min="1" max="20000" className="form-control" onChange={(e) =>{setAmount(e.target.value)}}  />
                
                </div>}
                
                </div>
              { utrShow && <button className="btn btn-primary mb-4" onClick={()=>onPay()}>Pay</button>}

            
                </div>
                </div>
          {!utrShow &&            <div className="p-3 " >
      
        <div className="">
    
          <div className="">
          <div class="">
        
        <div class="">
            <div class="">
        
                    <div class="row row-cols-xl-6 row-cols-lg-5 text-center  row-cols-md-5 row-cols-sm-5 row-cols-4 gy-2">

             
                     <div className="w-100 text-center  d-flex justify-content-center">
                     <div class="  text-center" style={{width:'48%'}}>
                          <div className="mx-auto" style={{border:"2px solid #000"}}>
                          <img src={qrcode} alt="logo" style={{width:'100%',padding:"10px"}} class="rounded-3 mx-auto d-table"/>
                          </div>
                       
                        </div>
                        
                     </div>
                     <div class="w-100" style={{textTransform:'capitalize',fontWeight:'bold'}}>
                          scan and deposite now

                          &#128070;
                        </div>
                        <div className="text-center w-100 text-danger " style={{fontWeight:'bold',fontSize:'20px'}}>or</div>


                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">

                         
                        
                              <div>

                                <div className="card-body pt-0">
            <div className="form-group">
              <div className="d-flex flex-column justify-content-center align-items-center">
                {/* <label htmlFor="amount" className="form-label w-100 text-start">Enter Amount</label>
                {utrShow && <div className="input-group mb-4">
                  <span className="input-group-text bg-light text-dark">₹</span>
                  <input type="number" placeholder="Amount" id="amount" min="1" max="20000" className="form-control" onChange={(e) =>{setAmount(e.target.value)}}  />
                
                </div>} */}
              { !utrShow && <div className="input-group mb-4 me-2 mes-2">
                  <input  className="form-control " style={{ fontSize:"25px",fontWeight:"bold"}} ref={textRef} value={upi}/>
                  <button onClick={handleCopyClick} className="Btnnewadd">   <span class="textnewadd">Copy</span>
  <span class="svgIconnewadd">
    <svg fill="white" viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path></svg>
  </span></button>
                </div>
}

             
                <div id="whichPG" className="d-flex justify-content-evenly align-items-center mb-2 w-100">
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="cashfree" className="form-check-input" value="cashfree" />
                    <label htmlFor="cashfree" className="form-check-label">
                      <h5 className="text-capitalize">UPI</h5>
                    </label>
                  </div> */}
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="phonepe" className="form-check-input" value="phonepe" checked />
                    <label htmlFor="phonepe" className="form-check-label">
                      <h5 className="text-capitalize">UPI-2</h5>
                    </label>
                  </div> */}
                </div>
                <div>
              { utrShow && <button className="btn btn-primary" onClick={()=>onPay()}>Pay</button>}
                 
                </div>
                
                <div className="d-flex flex-column justify-content-center align-items-center">
               
                <div className="input-group">
               

{/*                  
                 {!utrShow && 
                 
                 
                 
                 <input type="number" placeholder="Amount" id="amount" min="1" max="20000" className="form-control" value={amount}  />} */}
                </div>

                {!utrShow && <img></img>}
           
             
               { !utrShow &&<label htmlFor="amount" className="form-label w-100 text-start">Plese Enter Transaction ID</label>}
               { !utrShow &&<div className="input-group mb-4">
                  <input  className="form-control"  onChange={(e) =>{setUtr(e.target.value)}} />
           
              </div>}
              { !utrShow &&<div className="input-group mb-4 w-100">
                { !utrShow &&<label htmlFor="amount" className="form-label w-100 text-start">Plese Upload Payment Screen Shot</label>}
                  <input type="file" id="image3" className="w-100" name="image3" accept="image/*" onChange={handleInputChange1} required ></input>
           
              </div>}
            
                <div>
     
               

                 {!utrShow && <button className="btn btn-primary"  onClick={()=>onRecharge()}> Submit </button>}
                </div>
              </div>
              </div>
            </div>
          </div>
                              </div>
                            
                            <hr class="my-1"/>

                        </div>
                      

        </div>
        <div className="d-flex justify-content-center align-items-center">
    <div className="hstack gap-2">
      <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/gpay.svg" alt="gpay logo" width="48" />
      <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/paytm.svg" alt="paytm logo" width="48" />
      <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/phonepe.svg" alt="phonepe logo" width="48" />
      <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/upi.svg" alt="upi logo" width="48" />
    </div>
  </div>
  <div role="alert" className="fade d-flex align-items-center justify-content-between alert alert-warning show" style={{ fontSize: '0.7rem', textAlign: 'unset' }}>
            <span>
              <b>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20" fill="red">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                </svg>
                &nbsp;&nbsp;यदि आप किसी भी पेमेंट का बैंक से Complain डालते है तो आपके खाते को Block कर दिया जायेगा ! इसलिए किसी और से अपने Ludo ID में पैसे न डलवाये ! और यदि आप खुद जान भूझकर बैंक से रिफंड लेने के लिए Complain डालते है तो आपकी Ludo ID पूर्णतः बंद कर दी जाएगी !
              </b>
            </span>
          </div>
                
         
                </div>
                
            </div>
            
            <div>

</div>
        </div>
          </div>
        </div>
  
      </div> }

     
        </div>
        
    
      </div>
      <div className="mb-3  shadow card">
        <div class="games-section-title m-4 mb-0">Choose amount to add</div>

        <div class="games-window m-4 mt-0 mb-0">
          <div class="gameCard-container ">
              <div class="add-fund-box"  >
         <div className="mainlable">
         <div className="imagemailable">
               <img className="lableadd" src={lable} alt="" />
               <span className="labletext">+{offerData[0].offer}%</span>
         </div>
         </div>
                  <div style={{display:"flex",alignItems:"baseline"}} onClick={()=>onOfferPay(offerData[0])}>
                    <div class="collapseCard-title mr-1"  style={{fontSize:"0.9em"}}>₹</div>
                      <div class="collapseCard-title" style={{fontSize:"0.9em"}} onClick={()=>onOfferPay(1000)}>{offerData[0].price}</div>
                  </div>
              </div>
              
          </div>
          <div class="gameCard-container">
              <div class="add-fund-box">
         <div className="mainlable">
         <div className="imagemailable">
               <img className="lableadd" src={lable} alt="" />
               <span className="labletext">+{offerData[1].offer}%</span>
         </div>
         </div>
                  <div style={{display:"flex",alignItems:"baseline"}} onClick={()=>onOfferPay(offerData[1])}>
                    <div class="collapseCard-title mr-1"  style={{fontSize:"0.9em"}}>₹</div>
                      <div class="collapseCard-title" style={{fontSize:"0.9em"}}>{offerData[1].price}</div>
                  </div>
              </div>
              
          </div>
          <div class="gameCard-container">
              <div class="add-fund-box">
         <div className="mainlable">
         <div className="imagemailable">
               <img className="lableadd" src={lable} alt="" />
               <span className="labletext">+{offerData[2].offer}%</span>
         </div>
         </div>
                  <div style={{display:"flex",alignItems:"baseline"}} onClick={()=>onOfferPay(offerData[2])}>
                    <div class="collapseCard-title mr-1"  style={{fontSize:"0.9em"}}>₹</div>
                      <div class="collapseCard-title" style={{fontSize:"0.9em"}}>{offerData[2].price}</div>
                  </div>
              </div>
              
          </div>
          <div class="gameCard-container">
              <div class="add-fund-box">
         <div className="mainlable">
         <div className="imagemailable">
               <img className="lableadd" src={lable} alt="" />
               <span className="labletext">+{offerData[3].offer}%</span>
         </div>
         </div>
                  <div style={{display:"flex",alignItems:"baseline"}} onClick={()=>onOfferPay(offerData[3])}>
                    <div class="collapseCard-title mr-1"  style={{fontSize:"0.9em"}}>₹</div>
                      <div class="collapseCard-title" style={{fontSize:"0.9em"}}>{offerData[3].price}</div>
                  </div>
              </div>
              
          </div>
          <div class="gameCard-container">
              <div class="add-fund-box">
         <div className="mainlable">
         <div className="imagemailable">
               <img className="lableadd" src={lable} alt="" />
               <span className="labletext">+{offerData[4].offer}%</span>
         </div>
         </div>
                  <div style={{display:"flex",alignItems:"baseline"}} onClick={()=>onOfferPay(offerData[4])}>
                    <div class="collapseCard-title mr-1"  style={{fontSize:"0.9em"}}>₹</div>
                      <div class="collapseCard-title" style={{fontSize:"0.9em"}}>{offerData[4].price}</div>
                  </div>
              </div>
              
          </div>
          <div class="gameCard-container">
              <div class="add-fund-box">
         <div className="mainlable">
         <div className="imagemailable">
               <img className="lableadd" src={lable} alt="" />
               <span className="labletext">+{offerData[5].offer}%</span>
         </div>
         </div>
                  <div style={{display:"flex",alignItems:"baseline"}} onClick={()=>onOfferPay(offerData[5])}>
                    <div class="collapseCard-title mr-1"  style={{fontSize:"0.9em"}}>₹</div>
                      <div class="collapseCard-title" style={{fontSize:"0.9em"}}>{offerData[5].price}</div>
                  </div>
              </div>
              
          </div>
    
          
        </div>


     
        </div>
    </div>
  );
};

export default Recharge;