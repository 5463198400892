import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../globalContext';
import {useNavigate} from 'react-router-dom';
import ludo from '../../images/ludo/ludoavt.svg';
import {Input, Modal, message} from 'antd';
import axiosInstance from '../../axiosInstance';
import trophy from '../../static/media/trophy.png';
import vs from '../../static/media/vs.png';
const Game2 = () => {
  const user = useContext(UserContext);
  let navigate = useNavigate();
  const [amount, setAmount] = useState(null);
  const [runingGame, setRuningGame] = useState();
  const [runingTable, setRuningTable] = useState();
  const [runingTable2, setRuningTable2] = useState();
  const [runingTableStatus, setRuningTableStatus] = useState(false);
  const [runingTableStatus2, setRuningTableStatus2] = useState(false);
  const [runing, setRuning] = useState(false);
  const [walletAmount, setWalletAmount] = useState();
  const [playWait, setPlayWait] = useState(false);
  const [playWait11, setPlayWait11] = useState(false);
  const [joinedTableStatus, setJoinedTableStatus] = useState(false);
  const [joinedTable, setJoinedTable] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user.userId == null) {
        window.location.reload(true);
      }
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getPayment();
    getRuningGame();
    getRuningTable(0);
    getRuningTable2()
  };


  useEffect(() => {
    const interval = setInterval(() => {
      getRuningTable(0);
      getRuningTable2();
      getJoinedTable();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getJoinedTable = () => {
    var  Joined = []
    axiosInstance.post(`game2/runing/all`).then(response => {

      if (response.data.data.length !== 0) {
        Joined.push(...response.data.data)
        axiosInstance.post(`game/runing/all`).then(response => {
          if (response.data.data.length !== 0) {
            Joined.push(...response.data.data)
            const sortedData = [...Joined].sort(
              (a, b) => new Date(b.gameJoinedDate) - new Date(a.gameJoinedDate),
            );
            setJoinedTableStatus(true);
          
    
            setJoinedTable(sortedData);
          } 
          else{
            const sortedData = [...Joined].sort(
              (a, b) => new Date(b.gameJoinedDate) - new Date(a.gameJoinedDate),
            );
            setJoinedTableStatus(true);
            console.log("joinedgame",sortedData)
    
            setJoinedTable(sortedData);
          }
        });
      } else {
        axiosInstance.post(`game/runing/all`).then(response => {

          if (response.data.data.length !== 0) {

            Joined.push(...response.data.data)
            const sortedData = [...Joined].sort(
              (a, b) => new Date(b.gameJoinedDate) - new Date(a.gameJoinedDate),
            );
          console.log("sortedDataaaaa",sortedData)

            setJoinedTableStatus(true);
          
            setJoinedTable(sortedData);
          } 
          else{
            setJoinedTableStatus(false);
          }
        });
        
      }
      

    });
  };




  const getPayment = () => {
    axiosInstance.get(`wallet/${user.userId}`).then(response => {
      setWalletAmount(
        response.data.data.winningAmount + response.data.data.depositeAmount,
      );
    });
  };
  const getRuningTable = () => {
    axiosInstance.get(`/game2`).then(response => {
      if (response.data.data !== null) {
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
        );
        setRuningTableStatus(true);

        setRuningTable(sortedData);
      } else {
        setRuningTableStatus(false);
      }
    });
  };
  const getRuningTable2 = () => {
    axiosInstance.get(`/game`).then(response => {
          console.log("game1 data",response.data.data)
      if (response.data.data !== null) {
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
        );
        setRuningTableStatus2(true);

        setRuningTable2(sortedData);
      } else {
        setRuningTableStatus2(false);
      }
    });
  };
  const getRuningGame = () => {
    axiosInstance.get(`game2/runing/${user.userId}`).then(response => {
      if (response.data.data !== null) {
        setRuning(true);
        setRuningGame([response.data.data]);
      } else {
        if (response.data.data === null) {
          axiosInstance.get(`game2/runing1/${user.userId}`).then(response1 => {
            if (response1.data.data !== null) {
              setRuning(true);
              setRuningGame([response1.data.data]);
            } else {
              if (response1.data.data === null) {
                axiosInstance
                  .get(`game2/runing2/${user.userId}`)
                  .then(response2 => {
                    if (response2.data.data !== null) {
                      setRuning(true);
                      setRuningGame([response2.data.data]);
                    } else {
                      if (response2.data.data === null) {
                        axiosInstance
                          .get(`game2/runing3/${user.userId}`)
                          .then(response3 => {
                            if (response3.data.data !== null) {
                              setRuning(true);
                              setRuningGame([response3.data.data]);
                            } else {
                              if (response3.data.data === null) {
                                setRuning(false);
                                getRuningGame();
                              }
                            }
                          });
                      }
                    }
                  });
              }
            }
          });
        }
      }
    });
  };
  const onPayment = e => {
    setAmount(e.target.value);
  };
  const onPlayGame = e => {
    if (amount > 49) {
      if (walletAmount > amount) {
        if (
          amount === '50' ||
          amount === '100' ||
          amount === '200' ||
          amount === '500' ||
          amount === '1000' ||
          amount === '5000'
        ) {
          navigate(`/Game1`, {replace: true});
        } else {
          setPlayWait(true);

          if (amount !== null && amount !== '') {
            axiosInstance.get(`game2/runing/${user.userId}`).then(response => {
              if (response.data.data !== null) {
                setRuning(true);
                setRuningGame([response.data.data]);
                message.error(
                  'You are already in the game.please complete the game first. And play again...!',
                );
                setPlayWait(false);
              } else {
                if (response.data.data === null) {
                  axiosInstance
                    .get(`game2/runing1/${user.userId}`)
                    .then(response1 => {
                      if (response1.data.data !== null) {
                        setRuning(true);
                        setRuningGame([response1.data.data]);
                        message.error(
                          'You are already in the game.please complete the game first. And play again...!',
                        );
                        setPlayWait(false);
                      } else {
                        if (response1.data.data === null) {
                          axiosInstance
                            .get(`game2/runing2/${user.userId}`)
                            .then(response2 => {
                              if (response2.data.data !== null) {
                                setRuning(true);
                                setRuningGame([response2.data.data]);
                                message.error(
                                  'You are already in the game.please complete the game first. And play again...!',
                                );
                                setPlayWait(false);
                              } else {
                                if (response2.data.data === null) {
                                  axiosInstance
                                    .get(`game2/runing3/${user.userId}`)
                                    .then(response3 => {
                                      if (response3.data.data !== null) {
                                        setRuning(true);
                                      
                                        setRuningGame([response3.data.data]);
                                        message.error(
                                          'You are already in the game.please complete the game first. And play again...!',
                                        );
                                        setPlayWait(false);
                                      } else {
                                        if (response3.data.data === null) {
                                          setRuning(false);
                                          onPlayGame2(amount);
                                        }
                                      }
                                    });
                                }
                              }
                            });
                        }
                      }
                    });
                }
              }
            });
          } else {
            alert('Please enter valid amount...!');
            message.error('Please enter valid amount...!');
          }
        }
      } else {
        message.error('Insufficient chips...!');
        navigate(`/Recharge`);
      }
    } else {
      message.error('Please enter amount 50 or above 50...!');
    }
  };

  const onPlayGame4 = (e) => {
    var ii ;
    Modal.confirm ({
      
      title: <div>
      
        <div className='mt-0'> <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
        Enter Classic v2 Room Code
        </div>
        <div class="card-body">
          <div className='pt-0'>
          <div role="alert" class="mb-2 fade text-center alert alert-primary addextra show text-dark">
              कृपया सही रूम कोड <span className='text-danger'>(ROOM CODE)</span> डाले ।
              </div>
          </div>
        {/* <label>Enter Classic v2 Room Code</label> */}
        <Input placeholder='Enter Room Code' className='shadowmyadd' type='Number' onChange={(el) => {
       ii = (el.target.value)
        }} />
        
          <div>
            <div className='pt-2 m-0'>
             
              <div role="alert" class="m-0  fade alert alert-primary addextra show text-dark">
              NOTE:- यदि आपके <span className='text-danger'>ROOM CODE</span> गलत पाया जाता है तो आपका गेम हारा हुआ माना जाएगा ।

             <p className='pt-1 m-0'> टेबल लगने के बाद आप <span className='text-danger'>ROOM CODE</span> चेंज नहीं कर सकते ।</p>
              </div>
             
            
            </div>
          </div>
        </div>
      </div></div>
      </div>,
      okText: "Yes",
      okType: "succes",
      cancelText: "No",
      onOk() {

      },
      CancelText: "Yes",
      CancelType: "Danger",
      cancelText: "No",
      onCancel() {},
    });
  };
  const onPlayGame1 = () => {
    var ii ;
    Modal.confirm ({
      
      title: <div>
      
        <div className='mt-0'> <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
        Enter Classic v2 Room Code
        </div>
        <div class="card-body">
          <div className='pt-0'>
          <div role="alert" class="mb-2 fade text-center alert alert-primary addextra show text-dark">
              कृपया सही रूम कोड <span className='text-danger'>(ROOM CODE)</span> डाले ।
              </div>
          </div>
        {/* <label>Enter Classic v2 Room Code</label> */}
        <Input placeholder='Enter Room Code' className='shadowmyadd' type='Number' onChange={(el) => {
       ii = (el.target.value)
        }} />
        
          <div>
            <div className='pt-2 m-0'>
             
              <div role="alert" class="m-0  fade alert alert-primary addextra show text-dark">
              NOTE:- यदि आपके <span className='text-danger'>ROOM CODE</span> गलत पाया जाता है तो आपका गेम हारा हुआ माना जाएगा ।

             <p className='pt-1 m-0'> टेबल लगने के बाद आप <span className='text-danger'>ROOM CODE</span> चेंज नहीं कर सकते ।</p>
              </div>
             
            
            </div>
          </div>
        </div>
      </div></div>
      </div>,
      okText: "Yes",
      okType: "succes",
      cancelText: "No",
      onOk() {
        if(ii === undefined) {
          setPlayWait(false);
          message.error("please provide a valid ROOM CODE")
        }
        else{
          const data = {
            gameDate: new Date(),
            player1: user.userId,
            status: 'created',
            gameAmount: amount,
            playerCount: 1,
            winningAmount: amount * 2 - amount * 0.1,
            roomCode: ii,
            debitAmount: false,
          };
          if (
            data.player1 !== undefined &&
            data.gameAmount !== undefined &&
            data.winningAmount !== undefined &&
            data.status !== undefined &&
            data.roomCode !== undefined &&
            data.roomCode !== '' &&
            data.status !== '' &&
            data.winningAmount !== '' &&
            data.gameAmount !== '' &&
            data.player1 !== ''
          ) {
            axiosInstance.post('/game2', data).then(res => {
              if (res.data && res.data.responseCode === -1) {
                message.error('Record Already Exists');
              } else if (res.data && res.data.responseCode === 1) {
                navigate(
                  `/GameRoom2/${encodeURIComponent(JSON.stringify(res.data.data))}`,
                  {replace: true},
                );
              } else message.error('Something wrong. Please try again...!');
            });
          } else {
            message.error(
              'Please fill out all required fields. And submit again...!',
            );
          }
        }
    
      },
      CancelText: "Yes",
      CancelType: "Danger",
      cancelText: "No",
      onCancel() {
        setPlayWait(false);

      },
    });
  };

  const onPlayGame2 = ((e) => {
    if (runing === false) {
      if(walletAmount > e-1){

      axiosInstance.post('/game2/user/status', {gameAmount: e}).then(res => {
        if (res.data.data === null) {
          onPlayGame1(e);
        } else {
          axiosInstance
            .put(`/game2/${res.data.data._id}`, {
              status: 'joined',
              playerCount: 2,
              player2: user.userId,
            gameJoinedDate:new Date()

            })
            .then(response => {
              if (response.data.responseCode === 1) {
                axiosInstance.get(`/game2/${res.data.data._id}`).then(res => {
                  navigate(
                    `/GameRoom2/${encodeURIComponent(
                      JSON.stringify(res.data.data),
                    )}`,
                    {replace: true},
                  );
                });
              }
            });
        }
      });
    }
    else {
      message.error("Insufficient chips...!");
      navigate(`/Recharge`);
  
    }
       
    } else {
      alert('You are already in a game...!');
      message.error('You are already in a game...!');
    }
  
  });

  const onViewGame = e => {
    navigate(
      `/GameRoom2/${encodeURIComponent(JSON.stringify(runingGame[0]))}`,
      {replace: true},
    );
  };


  const onPlayGame11 = e => {
    if (walletAmount > e-1) {
      setPlayWait11(e);
      axiosInstance.get(`game/runing/${user.userId}`).then(response => {
        if (response.data.data !== null) {
          setRuning(true);
          setRuningGame([response.data.data]);
          message.error(
            'You are already in the game.please complete the game first. And play again...!',
          );
          setPlayWait11(false);
        } else {
          if (response.data.data === null) {
            axiosInstance.get(`game/runing1/${user.userId}`).then(response1 => {
              if (response1.data.data !== null) {
                setRuning(true);
                setRuningGame([response1.data.data]);
                message.error(
                  'You are already in the game.please complete the game first. And play again...!',
                );
          setPlayWait11(false)

              } else {
                if (response1.data.data === null) {
                  axiosInstance
                    .get(`game/runing2/${user.userId}`)
                    .then(response2 => {
                      if (response2.data.data !== null) {
                        setRuning(true);
                        setRuningGame([response2.data.data]);
                        message.error(
                          'You are already in the game.please complete the game first. And play again...!',
                        );
          setPlayWait11(false)

                      } else {
                        if (response2.data.data === null) {
                          axiosInstance
                            .get(`game/runing3/${user.userId}`)
                            .then(response3 => {
                              if (response3.data.data !== null) {
                                setRuning(true);
                                setRuningGame([response3.data.data]);
                                message.error(
                                  'You are already in the game.please complete the game first. And play again...!',
                                );
          setPlayWait11(false)

                              } else {
                                if (response3.data.data === null) {
                                  setRuning(false);
                                  onPlayGame112(e);
                                }
                              }
                            });
                        }
                      }
                    });
                }
              }
            });
          }
        }

        //setWalletAmount(response.data.data.amount);
      });
    } else {
      message.error('Insufficient chips...!');
      navigate(`/Recharge`);
    }
  };

  const onPlayGame112 = e => {
    // if(walletAmount>e){

    axiosInstance.post('/game/user/status', {gameAmount: e}).then(res => {
      if (res.data.data === null) {
        onPlayGame1(e);
      } else {
        axiosInstance
          .put(`/game/${res.data.data._id}`, {
            status: 'joined',
            playerCount: 2,
            player2: user.userId,
            gameJoinedDate:new Date()
          })
          .then(response => {
            if (response.data.responseCode === 1) {
              message.success('Table created successfully');
              axiosInstance.get(`/game/${res.data.data._id}`).then(response => {
                navigate(
                  `/GameRoom/${encodeURIComponent(
                    JSON.stringify(response.data.data),
                  )}`,
                  {replace: true},
                );
              });
            }
          });
      }
    });
    //   }
    // else {
    //   message.error("Insufficient chips...!");
    //   navigate(`/Recharge`);

    // }
  };


  return (
    <div className="realludokingsize">
      <div class="d-flex flex-column">
        <div class="bg-gray-200 h-100 w-100 p-3 bg-light d-flex align-items-center justify-content-between hstack gap-2">
          <div class="input-group flex-1 flex-nowrap">
            <input
              type="tel"
              class="form-control"
              id="inputSetChallenge"
              required=""
              name="amount"
              min="50"
              max="15000"
              placeholder="Amount"
              onChange={onPayment}
            />
            {!playWait && (
              <button
                type="button"
                class="btn btn-primary w-25"
                id="setBt"
                onClick={() => onPlayGame()}>
                Set
              </button>
            )}
            {playWait && (
              <button
              type="button"
              class="btn btn-primary w-25"
              id="setBt"
             >
             <div>
                <div class="spinner-border text-white" role="status"></div>
              </div>
            </button>
              
            )}
          </div>
        </div>
        <ul id="playable-challange-list" class="m-0 px-2">
          {runing && (
            <li class="p-0 overflow-hidden appear-from-left">
              <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-between card-header">
                  <span>Currently Running:</span>
                </div>
                <div className="d-flex align-items-center justify-content-between card-body ">
                  <div className="d-flex align-items-center flex-grow-1  " >
                    <img
                      src={trophy}
                      alt="avatar"
                      style={{height: '30px', width: '30px'}}
                    />
                    <span
                      className="fw-semibold text-truncate text-success ms-3"
                      style={{width: '100px', fontSize: '120%'}}>
                     Rs. {runingGame[0].gameAmount}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      <button
                        className="btn btn-primary playChallange btn-sm"
                        onClick={() => onViewGame()}>
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )}
          {runingTableStatus && (
            <div>
              {runingTable &&
                runingTable[0] &&
                runingTable.map(el => (
                  <li className="p-0 overflow-hidden appear-from-left">
                       <div className=' p-agggg'>
                       <div className="my-2 card shadow02">
                      <div className="d-flex align-items-center justify-content-between card-header">
                        <span>Challenge set by</span>
                        <span className="text-success fw-bold">
                          Rs {el.gameAmount}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between card-body">
                        <div className="d-flex align-items-center flex-grow-1">
                          <div
                            className="bg-dark rounded-circle me-2"
                            style={{height: '24px', width: '24px'}}>
                            <img src={ludo} alt="avatar" />
                          </div>
                          <span
                            className="fw-semibold text-truncate text-start"
                            style={{width: '100px'}}>
                            {el.player1.name}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="hstack gap-2">
                            <button
                              className="btn btn-primary playChallange btn-sm"
                              onClick={() => onPlayGame2(`${el.gameAmount}`)}>
                              Play
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                       </div>
                   
                  </li>
                ))}
            </div>
          )}
          {runingTableStatus2 && (
            <div>
              {runingTable2 &&
                runingTable2[0] &&
                runingTable2.map(el => (
                  <li className="p-0 overflow-hidden appear-from-left">
                       <div className=' p-agggg'>
                       <div className="my-2 card shadow02">
                      <div className="d-flex align-items-center justify-content-between card-header">
                        <span>Challenge set by</span>
                        <span className="text-success fw-bold">
                          Rs {el.gameAmount}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between card-body">
                        <div className="d-flex align-items-center flex-grow-1">
                          <div
                            className="bg-dark rounded-circle me-2"
                            style={{height: '24px', width: '24px'}}>
                            <img src={ludo} alt="avatar" />
                          </div>
                          <span
                            className="fw-semibold text-truncate text-start"
                            style={{width: '100px'}}>
                            {el.player1.name}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="hstack gap-2">
                          {  playWait11!==`${el.gameAmount}` && <button
                              className="btn btn-primary playChallange btn-sm"
                              onClick={() => onPlayGame11(`${el.gameAmount}`)}>
                              Play
                            </button>}
                            {playWait11===`${el.gameAmount}` && 
              <button
              type="button"
              class="btn btn-primary "
              id="setBt"
             >
             <div>
                <div class="spinner-border text-white" role="status"></div>
              </div>
            </button>
              
           }
                          </div>
                        </div>
                      
                      </div>
                    </div>
                       </div>
                   
                  </li>
                ))}
            </div>
          )}
        </ul>

       <div className='card-body pt-0 pb-0  '>
        <marquee className='card-body myjikj adcj' >
          <div className='d-flex'>
            
        <h2 className=' kl02 m-0 text-white'>Running Tables &#129351;...</h2>
        <h2 className=' kl02 m-0 text-warning'>Running Tables &#128142;...</h2>
          </div>

</marquee> 
        </div>

      { joinedTableStatus && (
      <div>
        {joinedTable &&
                joinedTable[0] &&
                joinedTable.map(el => (
      <ul id="playable-challange-list" class="m-0 px-2">
          <li className="p-0 overflow-hidden appear-from-left">
            <div className=' p-agggg'>
            <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      {el.player1.name}
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      {el.player2.name}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs {el.winningAmount}</span>
                </div>
              </div>
            </div>
            </div>
          
          </li>
          
        </ul>
                ))}
        </div>
        )}










        {/* <div className='card-body pt-0 pb-0  '>
        <marquee className='card-body myjikj adcj' >
          <div className='d-flex'>
            
        <h2 className=' kl02 m-0 text-white'>Completed Tables &#129351;...</h2>
        <h2 className=' kl02 m-0 text-warning'>Completed Tables &#128142;...</h2>
          </div>

</marquee> 
        </div> */}
        
        <ul id="playable-challange-list" class="m-0 px-2">
          <li className="p-0 overflow-hidden appear-from-left">
            <div className=' p-agggg'>
            <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Byan
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Raju Kako
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 2300</span>
                </div>
              </div>
            </div>
            </div>
          
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      dalukhan54
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Ajay Choudhary
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 800</span>
                </div>
              </div>
            </div>
          </div>
           
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Aman Jangir
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Monu Meena
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 5500</span>
                </div>
              </div>
            </div>
          </div>

           
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Kamal Kumawat
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Manish Meena
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 600</span>
                </div>
              </div>
            </div>
          </div>

            
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Vikash Yadav
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Rupali Bano
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 15000</span>
                </div>
              </div>
            </div>
          </div>

          
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Hemraj Choudhary
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      King Of Randas
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 1500</span>
                </div>
              </div>
            </div>
          </div>

          
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Kamal Bagra
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Lali Bura
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 1300</span>
                </div>
              </div>
            </div>
          </div>

            
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Akshay Meena
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Rohit Kumawat
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 8500</span>
                </div>
              </div>
            </div>
          </div>

            
          </li>
          <li className="p-0 overflow-hidden appear-from-left">
          <div className=' p-agggg'>
          <div className="my-2 card shadow02">
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Khusi Kanwar
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="30" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-truncate"
                      style={{width: '80px'}}>
                      Ganesh Kumawat
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center pt-3">
                  <span className="text-success fw-bold">Rs 2800</span>
                </div>
              </div>
            </div>
          </div>

            
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Game2;
