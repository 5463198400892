import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useNavigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {UserContext} from '../../globalContext';
import axiosInstance from '../../axiosInstance';

const Wallet = props => {
  const user = useContext(UserContext);
  let navigate = useNavigate();
  const [walletData , setWallet] = useState(null)

  useEffect(() => {
    let mounted = true;
    if (mounted) {

      if (user.userId == null) {
        window.location.reload(true);
      }
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWallet();
    checkRecharge();
  };
const checkRecharge = () =>{
  axiosInstance.get(`/payment/${user.userId}`).then((res)=>{

    if(res.data.data!==null && res.data.data!==undefined){

      verifyRecharge(res.data.data)
    }
  })
}
const verifyRecharge = (e) =>{
  const data={
    user_token: "eb0c44f223f310f7a6bc94432b44d9e9",
   order_id: e.orderId,
    //order_id: 8787772321801,
  } 


  axiosInstance.post(`/roomeCode/payment/check`,data).then((res)=>{


    if(res.data.result.status === "SUCCESS"){
      axiosInstance.put(`/payment/${e._id}` ,{status:"success"}).then((res) => {
        if (res.data && res.data.responseCode === 1) {
          putWallet(e);
          
    }
  })
}
    else{
      if(res.data.result.status === "PENDING"){
        axiosInstance.put(`/payment/${e._id}` ,{status:"failed"}).then((res) => {
          if (res.data && res.data.responseCode === 1) {
           
          } 
        });
      }}
    });
      }
    
 
  const getWallet = () => {
    axiosInstance.get(`/wallet/${user.userId}`).then((res) => {
        setWallet(res.data.data)
    });
  }
  var putP = 1 
  const putWallet = (e) => {
    axiosInstance.get(`/wallet/${user.userId}`).then((res) => {
      putP = putP +1
    
      if(putP===2){
        const data = {
          depositeAmount : res.data.data.depositeAmount+e.amount
        }
       axiosInstance.put(`/wallet/${user.userId}` , data).then((response) => {
        getWallet();

         });
     
      }
        
    });
  }
  return (
    <div className="col-12 g-0 iframe-sec p-3 realludokingsize">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start">
          <a href="#/HomeScreen">
            <button className="btn btn-primary border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                fill="currentColor"
                className="me-2">
                <path
                  fillRule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className="text-capitalize">Back</span>
            </button>
          </a>
        </div>
        <a className="text-capitalize btn btn-outline-primary" href="#/History">
          wallet history
        </a>
      </div>
     
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          deposit chips
        </div>
        <div class="card-body"><div><div><div role="alert" class="fade alert alert-primary show">यह चिप्स Spin &amp; Win एवं Buy की गई चिप्स है इनसे सिर्फ़ गेम खेले जा सकते है ॥ Bank या UPI में निकाला नहीं जा सकता है</div><div class="d-flex align-items-center justify-content-center px-2"><div class="d-flex flex-column align-items-center justify-content-center"><span class="text-capitalize fw-bold">chips</span>
       {walletData !== null  &&<span class="fs-4">{walletData.depositeAmount}</span>}
        </div></div><div class="d-flex flex-column align-items-stretch pt-4"><a class="text-decoration-none" href="#/Recharge"><div class="d-grid"><button class="btn btn-success btn-lg text-capitalize mb-2">add</button></div></a></div></div></div></div>
       
      </div>
      <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          winning chips
        </div>
        <div class="card-body">
          <div>
            <div>
              <div role="alert" class="fade alert alert-primary show">
                यह चिप्स गेम से जीती हुई है इन्हें Bank या
                UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला जा सकता है
              </div>
              <div class="d-flex align-items-center justify-content-center px-2">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <span class="text-capitalize fw-bold">chips</span>
                {walletData !== null  &&  <span class="fs-4">{walletData.winningAmount}</span>}
                </div>
              </div>
              <div class="d-flex flex-column align-items-stretch pt-4 pb-3">
                <a class="text-decoration-none" href="#/Withdraw">
                  <div class="d-grid">
                    <button class="btn btn-success btn-lg text-capitalize">
                      withdraw
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
          Bonus chips
        </div>
        <div class="card-body">
          <div>
            <div>
              <div role="alert" class="fade alert alert-primary show">
              ये चिप्स रेफरल से अर्जित किए जाते हैं। यह आपकी सभी Games में 1% उपयोग किया जाएगा। Bank या UPI में निकाला नहीं जा सकता है
              </div>
              <div class="d-flex align-items-center justify-content-center px-2">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <span class="text-capitalize fw-bold">chips</span>
                {walletData !== null  &&  <span class="fs-4">{walletData.avlbonus}</span>}
                </div>
              </div>
              <div class="d-flex flex-column align-items-stretch pt-4 pb-3">
                <a class="text-decoration-none" href="#/Withdraw">
                  <div class="d-grid">
                    <button class="btn btn-success btn-lg text-capitalize">
                      Bonus
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginBottom: '15px'}}>
        <ul className="list-group"></ul>
      </div>
    </div>
  );
};

export default Wallet;
