import React, { useContext, useEffect } from "react";

import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";

import ludo from "../static/media/new-board.png";

import call from "../static/media/live-chat.png";

import hello from "../static/media/4.png"
import axiosInstance from "../axiosInstance";
import ludo02 from "../static/media/new-board02.png";
import ludo03 from "../static/media/new-board03.png"
import io from 'socket.io-client';

import axios from "axios";
import post from "../images/ludo/ludopost.png"
const socket = io('http://localhost:6001');



const HomeScreen = (props) => {
  const user = useContext(UserContext);
  let navigate = useNavigate();

  useEffect(() => {


    // Receive messages from server
    socket.on('message', (msg) => {

         console.log(msg)
          
            // setMulty(msg)
            //     setChat((prevChat) => [...prevChat, msg]);
    });

    // Cleanup when component unmounts
    return () => {

            socket.off('message');
    };
}, []);





  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId === null) {
        localStorage.clear();
        navigate('/LoginScreen', { replace: true });
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWallet();
    onSubscribe();
  }
var numb = 0
  const onSubscribe = () => {
    if (numb == 0 ){
      numb = ++numb
      axiosInstance.get(`/subscription/${user.userId}`).then((res) => {
       
          subscribeToNotifications(res.data.data)

      })
    }
  
else{
  console.log("numb", numb)
 
  
}
   
  }



  const subscribeToNotifications = async (ndata) => {
    console.log("hiii2",ndata)

    // try {
    const registration = await navigator.serviceWorker.register('./service-worker.js');
    // console.log("hiii3",registration)

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: 'BKJAHhcyPfbIBuwzhLd3o7iFuLNQtZp6Bv-iMj9gPYsaTaL6tHUBFeaTj1_CMlMe1KngV_RuMhCobALy9VSP5GU'
    });
    // console.log("hiiindata",JSON.parse(ndata).endpoint)
    // console.log("hiii3",subscription.endpoint)

    //Send subscription object to backend


    var cdata = 0

    if (ndata.length == 0) {
      const data = {
        subscription: JSON.stringify(subscription),
        member: user.userId
      }
      console.log("data", data)

      axiosInstance.post(`/subscription`, data).then((res) => {
        console.log("resSubscription", res)
      })
    }
    else {

      ndata.map((k) => {
      
        if (JSON.parse(k.subscription).endpoint === subscription.endpoint) {
          cdata = 1 
        }
      })
      if(cdata !== 1 ){
         const data = {
      subscription: JSON.stringify(subscription),
      member:user.userId
    }
    console.log("data",data)

    axiosInstance.post(`/subscription` , data).then((res)=>{
    console.log("resSubscription",res)
    })
  }
   else{
    console.log("allready regestird")
   }  

      
    }


  };

  
  var count = 1;
  const getWallet = () => {
    axiosInstance.get(`/wallet/${user.userId}`).then((res) => {
      if (res.data.data === null) {
        count = count + 1;
        if (count < 5) {
          getWallet();
        }
        if (count === 5) {
          const data1 = {
            member: user.userId,
            amount: 20,
            winningAmount: 0,
            depositeAmount: 20,
            bonus: 0,
            winningFreezAmount: 0,
            totalWinningAmount: 0,
            RefralWinningAmount: 0,
            palenty: 0,
            avlbonus:0
          }
          axiosInstance.post("/wallet", data1).then((res) => {
          });
        }
      }
      else{
        console.log("datadara",res.data.data)
        if(res.data.data.avlbonus === undefined){
          getRefral(res.data.data._id)
        }
      }
    });
  }
  const getRefral = (e) => {

       
          const data1 = {
            palenty: 0,
            avlbonus:0
          }
          axiosInstance.put(`/wallet/${user.userId}`, data1).then((res) => {
            console.log("res",res)
          });
       
     
  }


  return (

    <div className="japurLudoNav realludokingsize" >
      <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div class="offcanvas-header">
          <div class="offcanvas-title h5" id="offcanvasBottomLabel">How To Play Games &amp; Earn?</div>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body small">
          Your Video
        </div>
      </div>
      <div className="homeScreenL">
        <div className=" col-12 mx-auto g-0 iframe-sec p-3">
          {/* <div role="alert" class="fade d-flex align-items-center justify-content-between alert alert-danger show">
        <span><b>KYC Pending <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20"
                    fill="red">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
                    </path>
                </svg></b></span><a href="#/kyc"><button class="btn btn-danger btn-sm text-capitalize">complete
                here</button></a></div> */}


          <div className="alert02">
            <div className="text-center mainwelcome" ><img src={hello} className="hello" alt="" />welcome IN Ludo King Jaipur.com</div>
          </div>
          <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner mt-3">
              <div class="carousel-item active">
                <img src={post} class="d-block w-100" alt="" />
              </div>
              <div class="carousel-item">
                <img src={post} class="d-block w-100" alt="" />
              </div>
              <div class="carousel-item">
                <img src={post} class="d-block w-100" alt="" />
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          <div class="d-flex align-items-center justify-content-between mt-3 mb-4">
            <h1 class="text-capitalize text-start mainh1
        ">games</h1><button type="button"
              class="d-flex align-items-center btn btn-outline-primary btn-md" ><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"
                class="me-1">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                </path>
              </svg><span class="text-capitalize" >guide</span></button>

          </div>

          <div className="mb-3 gx-3 row">
            <div className="col">

              <a className="text-decoration-none text-black" href="#/Game1">
                <picture>
                  <source
                    media="(min-width:1024px)"
                    srcSet={ludo}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={ludo}
                  />
                  <img
                    src={ludo}
                    alt="ludo classic"
                    className="rounded-3"
                    style={{ width: '100%', cursor: 'pointer' }}
                  />
                </picture>
              </a>
            </div>
            <div className="col">
              <a className="text-decoration-none text-black" href="#/Game2">
                <picture>
                  <source
                    media="(min-width:1024px)"
                    srcSet={ludo03}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={ludo03}
                  />
                  <img
                    src={ludo03}
                    alt="ludo classic"
                    className="rounded-3"
                    style={{ width: '100%', cursor: 'pointer' }}
                  />
                </picture>
              </a>
            </div>


          </div>
          <div className="mb-3 gx-3 row">

            <div className="col">

              <a className="text-decoration-none text-black" href="javascript:void(0)">
                <picture>
                  <source
                    media="(min-width:1024px)"
                    srcSet={ludo02}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={ludo02}
                  />
                  <img
                    src={ludo02}
                    alt="ludo classic"
                    className="rounded-3"
                    style={{ width: '100%', cursor: 'pointer' }}
                  />
                </picture>
              </a>



            </div>
            <div className="col">
              <a className="text-decoration-none text-black" href="javascript:void(0)">
                <picture>
                  <source
                    media="(min-width:1024px)"
                    srcSet={ludo02}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={ludo02}
                  />
                  <img
                    src={ludo02}
                    alt="ludo classic"
                    className="rounded-3"
                    style={{ width: '100%', cursor: 'pointer' }}
                  />
                </picture>
              </a>
            </div>
          </div>
          {/* <a class="bg-light border shadow rounded-circle d-flex align-items-center justify-content-center position-fixed text-dark meniicon" href="#/spinNwin" ><img src={spin} height="36px" alt="spin wheel icon"/></a> */}
          <a class="bg-light border shadow rounded-circle d-flex align-items-center justify-content-center position-fixed text-dark 23" href="#/support"><img src={call} height="36px" alt="spin wheel icon" /></a>


        </div>
      </div>






    </div>
  );
};

export default HomeScreen;